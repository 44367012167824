import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'
import authRoutes from './auth'
import siteRoutes from './sites'
import scanRoutes from './scans'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: { name: 'sites' }
  },
  ...authRoutes,
  ...siteRoutes,
  ...scanRoutes,
  {
    path: '*',
    name: '404',
    component: () => import(/* webpackChunkName: "404" */ '@/views/404.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  routes
})

// Route authentication guard
router.beforeEach((to, from, next) => {
  const isAuthenticated = store.getters['auth/loggedIn'];
  const hasTemporaryPassword = isAuthenticated && store.state.auth.user.challengeName === 'NEW_PASSWORD_REQUIRED'
  
  // Protect routes based on the 'public' meta value
  if (!to.meta.public && !isAuthenticated) {
    // Also need to catch anyone that is using a temporary password
    if (hasTemporaryPassword && to.name !== 'update-password') {
      next({ name: 'update-password' })
    } else {
      next({ name: 'login' })
    }
  } else {
    // Default is to let the user carry on
    next()
  }
})

export default router
