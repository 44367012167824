import { API, Storage } from 'aws-amplify'

export default {
    namespaced: true,
    state: () => ({
        scans: [],
        scan: null,
        results: [],
        transactions: [],
        submitting: false,
        updatedData: null
    }),
    getters: {
        scanList: state => state.scans,
        scan: state => state.scan,
        results: state => state.results,
        transactions: state => state.transactions,
        submitting: state => state.submitting,
        updatedData: state => state.updatedData
    },
    actions: {
        getScans: ({ commit }) => {
            commit('setScans', [])
            return API.get('API', `/scans`)
                .then(data => {
                    commit('setScans', data)
                })
                .catch(error => {
                    console.error(error)
                    commit('setSnackbar', {
                        message: 'Failed to fetch scans',
                        type: 'error'
                    }, { root: true })
                })
        },
        getSiteScans: ({ commit }, siteId = null) => {
            commit('setScans', [])
            return API.get('API', `/sites/${siteId}/scans`)
                .then(data => {
                    commit('setScans', data)
                })
                .catch(error => {
                    console.error(error)
                    commit('setSnackbar', {
                        message: 'Failed to fetch site scans',
                        type: 'error'
                    }, { root: true })
                })
        },
        getScanDetails: ({ commit }, id) => {
            return API.get('API', `/scans/${id}`)
                .then(data => {
                    commit('setScanDetails', data)
                })
                .catch(error => {
                    console.error(error)
                    commit('setSnackbar', {
                        message: 'Failed to fetch scan details',
                        type: 'error'
                    }, { root: true })
                })
        },
        getScanResults: async ({ commit, state }) => {
            const response = await Storage.get(state.scan.resultsPath, { download: true })
            const results = await new Response(response.Body).json()
            commit('setScanResults', results)
        },
        getScanTransactions: ({ commit }, {id, page, itemsPerPage}) => {
            commit('setScanTransactions', [])
            return API.get('API', `/scans/${id}/transactions`, {
                queryStringParameters: {
                    page,
                    itemsPerPage
                },
            })
                .then(data => {
                    commit('setScanTransactions', data)
                })
                .catch(error => {
                    console.error(error)
                    commit('setSnackbar', {
                        message: 'Failed to fetch scan transactions',
                        type: 'error'
                    }, { root: true })
                })
        },
        storeScan: ({ commit }, data) => {
            commit('setSubmitting', true)
            return API.post('API', '/scans', { body: data })
                .then(res => {
                    commit('setSubmitting', false)
                    commit('setUpdatedData', res)
                })
                .catch(error => {
                    console.error(error)
                    commit('setSubmitting', false)
                    commit('setSnackbar', {
                        message: 'Failed to create scan',
                        type: 'error'
                    }, { root: true })
                })
        },
        deleteScan: ({ commit }, id) => {
            return API.del('API', `/scans/${id}`)
                .catch(error => {
                    console.error(error)
                    commit('setSnackbar', {
                        message: 'Failed to delete scan',
                        type: 'error'
                    }, { root: true })
                })
        },
        stopScan: ({ commit }, id) => {
            return API.post('API', `/scans/${id}/stop`)
                .catch(error => {
                    console.error(error)
                    commit('setSnackbar', {
                        message: 'Failed to stop scan',
                        type: 'error'
                    }, { root: true })
                })
        }
    },
    mutations: {
        setScans: (state, data) => {
            state.scans = data
        },
        setScanDetails: (state, data) => {
            state.scan = data
        },
        setScanResults: (state, data) => {
            state.results = data
        },
        setScanTransactions: (state, data) => {
            state.transactions = data
        },
        setSubmitting: (state, status) => {
            state.submitting = status
        },
        setUpdatedData: (state, data) => {
            state.updatedData = data
        }
    }
}
