<template>
  <v-app>
    <navigation />

    <v-main>
      <v-container fluid>
        <v-container v-if="loading" fill-height>
          <loader />
        </v-container>
        <v-container v-else fluid>
          <router-view />
        </v-container>
      </v-container>
    </v-main>

    <snackbar />
  </v-app>
</template>

<script>
import Navigation from '@/components/Navigation'
import Loader from '@/components/Loader'
import Snackbar from '@/components/Snackbar'
import { mapGetters, mapMutations } from 'vuex'
import { Hub } from '@aws-amplify/core'

export default {
  name: 'App',

  components: {
    Navigation,
    Loader,
    Snackbar
  },

  computed: {
    ...mapGetters({
      loading: 'loading'
    })
  },

  beforeCreate() {
    Hub.listen('auth', data => {
      // Handle Google sign in here because the login page can't
      if (data.payload.event === 'signIn') {
        this.setCurrentUser(data.payload.data)
        this.$router.push({ name: 'sites' })
      }
    })
  },

  methods: {
    ...mapMutations({
      setCurrentUser: 'auth/setCurrentUser'
    }),
  }
}
</script>
