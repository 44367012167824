<template>
    <v-container fill-height>
      <v-progress-circular
          indeterminate
          color="primary"
          size="40"
          class="mx-auto"
      ></v-progress-circular>
    </v-container>
</template>

<script>
  export default { }
</script>
