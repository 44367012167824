<template>
  <v-app-bar
    app
    color="primary"
    dark
  >
    <v-app-bar-title class="mr-4">
      <div class="d-flex align-center">
        <!-- <v-img
          src="../assets/logo.png"
          width="28"
          class="d-inline-block mr-3"
        /> -->
        {{appName}}
      </div>
    </v-app-bar-title>

    <span v-if="loggedIn">
      <v-btn
        text
        class="mr-2"
        :to="{ name: 'sites' }"
      >Websites</v-btn>
      <v-btn
        text
        class="mr-2"
        :to="{ name: 'create-scan' }"
      >New Scan</v-btn>
    </span>

    <v-spacer></v-spacer>
    
    <theme-toggle class="mr-2" />

    <span v-if="loggedIn">
      <v-menu
        left
        bottom
        offset-y
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            icon
            small
            v-bind="attrs"
            v-on="on"
          >
            <v-icon>mdi-dots-vertical</v-icon>
          </v-btn>
        </template>

        <v-list>
          <v-list-item v-if="!oAuthEnabled" :to="{ name: 'change-password' }">
            <v-list-item-title>
              Change Password
            </v-list-item-title>
          </v-list-item>
          <v-list-item :to="{ name: 'logout' }">
            <v-list-item-title>
              <v-icon>mdi-logout</v-icon>
              Logout
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </span>

    <span v-else>
      <v-btn
        text
        :to="{ name: 'login' }"
      >Login</v-btn>

      <v-btn
        text
        class="mr-2"
        :to="{ name: 'register' }"
        v-if="registrationEnabled && !oAuthEnabled"
      >Register</v-btn>
    </span>
  </v-app-bar>
</template>

<script>
import ThemeToggle from './ThemeToggle'
import { mapGetters } from 'vuex'

export default {
  components: {
    ThemeToggle
  },

  data: () =>  ({
    appName: process.env.VUE_APP_NAME,
    oAuthEnabled: process.env.VUE_APP_ENABLE_OAUTH === 'true',
    registrationEnabled: process.env.VUE_APP_ENABLE_REGISTRATION === 'true'
  }),

  computed: {
    ...mapGetters({
      loggedIn: 'auth/loggedIn'
    })
  },
}
</script>
