import { API } from 'aws-amplify'

export default {
    namespaced: true,
    state: () => ({
        sites: [],
        site: null,
        submitting: false,
        updatedData: null
    }),
    getters: {
        siteList: state => state.sites,
        site: state => state.site,
        submitting: state => state.submitting,
        updatedData: state => state.updatedData
    },
    actions: {
        getSites: ({ commit }) => {
            commit('setSites', [])
            return API.get('API', '/sites')
                .then(data => {
                    commit('setSites', data)
                })
                .catch(error => {
                    console.error(error)
                    commit('setSnackbar', {
                        message: 'Failed to fetch sites',
                        type: 'error'
                    }, { root: true })
                })
        },
        getSiteDetails: ({ commit }, id) => {
            return API.get('API', `/sites/${id}`)
                .then(data => {
                    commit('setSiteDetails', data)
                })
                .catch(error => {
                    console.error(error)
                    commit('setSnackbar', {
                        message: 'Failed to fetch site details',
                        type: 'error'
                    }, { root: true })
                })
        },
        storeSite: ({ commit }, data) => {
            commit('setSubmitting', true)
            return API.put('API', '/sites', { body: data })
                .then(res => {
                    commit('setSubmitting', false)
                    commit('setUpdatedData', res)
                })
                .catch(error => {
                    console.error(error)
                    commit('setSubmitting', false)
                    commit('setSnackbar', {
                        message: 'Failed to create site',
                        type: 'error'
                    }, { root: true })
                })
        },
        deleteSite: ({ commit}, id) => { //modifed version of storeSite above, will definately need more tweaking
            return API.del('API', `/sites/${id}`) //should be delete?
                .catch(error => {
                    console.error(error)
                    commit('setSnackbar', {
                        message: 'Failed to delete site',
                        type: 'error'
                    }, {root: true})
                })
        }
    },
    mutations: {
        setSites: (state, sites) => {
            state.sites = sites
        },
        setSiteDetails: (state, site) => {
            state.site = site
        },
        setSubmitting: (state, status) => {
            state.submitting = status
        },
        setUpdatedData: (state, data) => {
            state.updatedData = data
        }
    }
}
