const routes = [
    {
        path: '/scans/new',
        name: 'create-scan',
        props: true,
        meta: {
            public: false
        },
        component: () => import(/* webpackChunkName: "scan-form" */ '@/views/scans/ScanForm.vue')
    },
    {
        path: '/scans/:id',
        name: 'scan-details',
        props: true,
        meta: {
            public: false
        },
        component: () => import(/* webpackChunkName: "scan-detail" */ '@/views/scans/ScanDetail.vue')
    },
    {
        path: '/scans/:id/report',
        name: 'scan-report',
        meta: {
            public: true
        },
        props: true,
        component: () => import(/* webpackChunkName: "scan-report" */ '@/views/scans/ScanReport.vue')
    }
]

export default routes
