import Vue from 'vue'
import { Amplify } from 'aws-amplify'
import moment from 'moment'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import titleMixin from './mixins/title'

Vue.config.productionTip = false

Vue.prototype.moment = moment

Vue.mixin(titleMixin)

Amplify.configure({
  Auth: {
    mandatorySignIn: false,
    region: process.env.VUE_APP_AWS_REGION,
    userPoolId: process.env.VUE_APP_COGNITO_USER_POOL_ID,
    userPoolWebClientId: process.env.VUE_APP_COGNITO_CLIENT_ID,
    identityPoolId: process.env.VUE_APP_COGNITO_IDENTITY_POOL_ID,
    oauth: process.env.VUE_APP_ENABLE_OAUTH === 'true' ? {
      domain: process.env.VUE_APP_COGNITO_DOMAIN,
      scope: ['email', 'profile', 'openid'],
      redirectSignIn: process.env.VUE_APP_URL,
      redirectSignOut: process.env.VUE_APP_URL + '/logout',
      responseType: 'code',
      clientId: process.env.VUE_APP_COGNITO_CLIENT_ID
    } : null
  },
  API: {
    endpoints: [{
      name: 'API',
      endpoint: process.env.VUE_APP_API_ENDPOINT,
      authorizationType: 'AWS_IAM'
    }]
  },
  Storage: {
    AWSS3: {
      bucket: process.env.VUE_APP_RESULTS_BUCKET_NAME,
      region: process.env.VUE_APP_AWS_REGION,
      customPrefix: {
        public: ''
      }
    }
  }
})

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
