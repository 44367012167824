import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'
import colors from 'vuetify/lib/util/colors'

Vue.use(Vuetify)

export default new Vuetify({
    theme: {
        dark: localStorage.getItem('theme') === 'dark',
        themes: {
            light: {
                primary: colors.orange.darken2
            },
            dark: {
                primary: colors.orange.darken2
            }
        }
    }
})
