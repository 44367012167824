function getPageTitle(vm) {
  const { title } = vm.$options
  if (title) {
    return typeof title === 'function' ? title.call(vm) : title
  }
}

export default {
  mounted() {
    const title = getPageTitle(this)
    if (title) {
      if (title === process.env.VUE_APP_NAME) {
        document.title = process.env.VUE_APP_NAME
      } else {
        document.title = `${title} | ${process.env.VUE_APP_NAME}`
      }
    }
  }
}
