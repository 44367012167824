import Vue from 'vue'
import Vuex from 'vuex'
import auth from './modules/auth'
import sites from './modules/sites'
import scans from './modules/scans'
import createPersistedState from 'vuex-persistedstate'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    loading: false,
    snackbar: null
  },
  getters: {
    loading: state => state.loading,
    snackbar: state => state.snackbar
  },
  mutations: {
    setLoading: (state, status) => {
      state.loading = status
    },
    setSnackbar: (state, data) => {
      state.snackbar = data
    }
  },
  actions: {
    setLoading: ({ commit }, status) => {
      commit('setLoading', status)
    },
    setSnackbar: ({ commit }, data) => {
      commit('setSnackbar', data)
    }
  },
  modules: {
    auth,
    sites,
    scans
  },
  plugins: [createPersistedState({
    paths: ['auth.user.signInUserSession.idToken.jwtToken']
  })]
})
