const routes = [
  {
    path: '/sites',
    name: 'sites',
    component: () => import(/* webpackChunkName: "sites" */ '@/views/sites/Index.vue')
  },
  {
    path: '/sites/new',
    name: 'create-site',
    component: () => import(/* webpackChunkName: "site-form" */ '@/views/sites/SiteForm.vue')
  },
  {
    path: '/sites/:id',
    name: 'site-details',
    props: true,
    component: () => import(/* webpackChunkName: "site-detail" */ '@/views/sites/SiteDetail.vue')
  },
  {
    path: '/sites/:id/edit',
    name: 'edit-site',
    props: true,
    component: () => import(/* webpackChunkName: "site-form" */ '@/views/sites/SiteForm.vue')
  },
]

export default routes
