import store from '../store'

const routes = [
  {
    path: '/login',
    name: 'login',
    meta: {
      public: true,
      title: 'Login'
    },
    component: () => import(/* webpackChunkName: "login" */ '@/views/auth/Login.vue')
  },
  {
    path: '/logout',
    name: 'logout',
    beforeEnter: async (to, from, next) => {
      await store.dispatch('auth/logout')
      next({ name: 'login' })
    }
  },
  ...process.env.VUE_APP_ENABLE_OAUTH === 'true' ? [
    {
      path: '/change-password',
      name: 'change-password',
      meta: {
        title: 'Change Password'
      },
      component: () => import(/* webpackChunkName: "change-password" */ '@/views/auth/ChangePassword.vue')
    },
    {
      path: '/update-password',
      name: 'update-password',
      meta: {
        title: 'Update Password'
      },
      component: () => import(/* webpackChunkName: "update-password" */ '@/views/auth/UpdatePassword.vue'),
      beforeEnter: (to, from, next) => {
        // Users should only be able to get to this page if they have a temporary password
        const hasTemporaryPassword = store.state.auth.user.challengeName === 'NEW_PASSWORD_REQUIRED'
        if (!hasTemporaryPassword) next({ name: 'sites' })
        else next()
      }
    },
    ...process.env.VUE_APP_ENABLE_REGISTRATION === 'true' ? [
      {
        path: '/register',
        name: 'register',
        meta: {
          public: true,
          title: 'Register'
        },
        component: () => import(/* webpackChunkName: "register" */ '@/views/auth/Register.vue')
      }
    ] : [],
  ] : []
]

export default routes
