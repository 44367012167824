import { Auth } from 'aws-amplify'

export default {
    namespaced: true,
    state: () => ({
        user: null,
        submitting: false
    }),
    getters: {
        loggedIn: state => !!state.user?.signInUserSession?.idToken?.jwtToken,
        user: state => state.user,
        submitting: state => state.submitting
    },
    actions: {
        login: ({ commit }, credentials) => {
            return new Promise((resolve, reject) => {
                commit('setSubmitting', true)
                Auth.signIn(credentials.email, credentials.password)
                    .then(user => {
                        commit('setSubmitting', false)
                        commit('setCurrentUser', user)
                        resolve(user)
                    })
                    .catch(error => {
                        console.error(error)
                        commit('setSubmitting', false)
                        reject(error)
                    })
            })
        },
        register: ({ commit }, data) => {
            return new Promise((resolve, reject) => {
                const userData = {
                    username: data.email,
                    password: data.password
                }

                commit('setSubmitting', true)
                Auth.signUp(userData)
                    .then(({ user }) => {
                        commit('setSubmitting', false)
                        commit('setCurrentUser', null)
                        resolve(user)
                    })
                    .catch(error => {
                        console.error(error)
                        commit('setSubmitting', false)
                        reject(error)
                    })
            })
        },
        loginWithGoogle: () => {
            return new Promise((resolve, reject) => {
                Auth.federatedSignIn({ provider: 'Google' })
                    .then(() => {
                        resolve()
                    })
                    .catch(error => {
                        console.error(error)
                        reject(error)
                    })
            })
        },
        logout: ({ commit }) => {
            return new Promise((resolve, reject) => {
                commit('setSubmitting', true)
                Auth.signOut()
                    .then(() => {
                        commit('setSubmitting', false)
                        commit('setCurrentUser', null)
                        resolve()
                    })
                    .catch(error => {
                        console.error(error)
                        commit('setSubmitting', false)
                        reject(error)
                    })
            })
        },
        newPassword: ({ commit }, data) => {
            return new Promise((resolve, reject) => {
                commit('setSubmitting', true)
                Auth.completeNewPassword(data.user, data.password)
                    .then(user => {
                        commit('setSubmitting', false)
                        commit('setCurrentUser', user)
                        resolve()
                    })
                    .catch(error => {
                        console.error(error)
                        commit('setSubmitting', false)
                        reject(error)
                    })
            })
        },
        changePassword: ({ commit }, data) => {
            return new Promise((resolve, reject) => {
                commit('setSubmitting', true)
                Auth.currentAuthenticatedUser()
                    .then(user => {
                        Auth.changePassword(user, data.oldPassword, data.newPassword)
                            .then(() => {
                                commit('setSubmitting', false)
                                resolve()
                            })
                            .catch(err => {
                                console.error(err)
                                commit('setSubmitting', false)
                                reject(err)
                            })
                    })
                    .catch(error => {
                        console.error(error)
                        commit('setSubmitting', false)
                        commit('setCurrentUser', null)
                        reject(error)
                    })
            })
        },
        currentUser: () => {
            return new Promise((resolve, reject) => {
                Auth.currentAuthenticatedUser()
                    .then(data => {
                        resolve(data)
                    })
                    .catch(error => {
                        console.error(error)
                        reject(error)
                    })
            })
        }
    },
    mutations: {
        setCurrentUser: (state, user) => {
            state.user = user
        },
        setSubmitting: (state, status) => {
            state.submitting = status
        },
    }
}
