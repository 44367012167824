<template>
  <v-snackbar
    v-model="show"
    :timeout="timeout"
    :color="color"
    :outlined="color ? true : false"
  >
    <v-icon
      v-if="type == 'error'"
      class="mr-1"
      :color="color"
    >mdi-alert-circle-outline</v-icon>
    {{message}}
    <template v-slot:action="{ attrs }">
      <v-btn
        :color="color ? color : 'blue'"
        text
        v-bind="attrs"
        @click="snackbar = false"
      >
        Close
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex'

  export default {
    data: () =>  ({
      show: false,
      message: '',
      timeout: 2000,
      type: undefined,
      color: undefined
    }),

    computed: {
      ...mapGetters({
        snackbar: 'snackbar'
      })
    },

    watch: {
      snackbar: function() {
        if (this.snackbar !== null && !this.show) {
          this.message = this.snackbar.message
          this.show = true
          if (this.snackbar.type) {
            this.type = this.snackbar.type
            this.color = 'error'
          } else {
            this.type = undefined
            this.color = undefined
          }
        }
      }
    },

    methods: {
      ...mapActions({
        setSnackbar: 'setSnackbar'
      })
    }
  }
</script>
